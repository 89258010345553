'use strict';

var player;

(function($, window, document, undefined) {

	var APP = window.APP = window.APP || {};

	APP.util = {
		exec: function(controller, action) {
			var ns = APP,
			act = (action === undefined) ? 'init' : action;

			if (controller !== '' && ns[controller] && typeof ns[controller][act] === 'function') {
				ns[controller][act]();
			}
		},
		init: function() {
			var el = document.getElementById('APP'), // Update the body ID here
			controller = el.getAttribute('data-controller'),
			actions = el.getAttribute('data-action');

			APP.util.exec('common');
			APP.util.exec(controller);

			// do all the actions too.
			$.each(actions.split(/\s+/), function(i, action){
				APP.util.exec(controller, action);
			});
		}
	};
	APP.common = (function() {


		var openModals = function(){

			if($('#contactModal .wpcf7-form.sent, #contactModal .wpcf7-form.invalid').length){
				$('#contactModal').modal('show');
			}
		};

		var sliderDMHomepage = function() {

			$('.dm-slider-home').slick({
				prevArrow: $('.slick-prev'),
				nextArrow: $('.slick-next'),
				arrows: true,
				pauseOnHover: false,
				autoplay: true,
				autoplaySpeed: 3000,
				dots: false,
				slide: '.single-slide',
				speed: 400,
				cssEase: 'easeOutExpo',
			});
		};

		var sliderDMProjectHomepage = function() {
			$('#dm-home-project-slider').slick({
				prevArrow: $('.slick-prev-project'),
				nextArrow: $('.slick-next-project'),
				arrows: true,
				pauseOnHover: false,
				autoplay: false,
				autoplaySpeed: 3000,
				dots: false,
				slide: '.slide-projet',
				speed: 400,
				cssEase: 'easeOutExpo',
				adaptiveHeight: true
			});
		};

		$("[slick-control]").click(function(){
			$('#dm-home-project-slider').slick('slickGoTo', $(this).attr('slick-control'));
			changeCurrentSelectedTab();
		});
		$('#dm-home-project-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
			changeCurrentSelectedTab();
		});
		function changeCurrentSelectedTab() {
			var current = $('#dm-home-project-slider').slick('slickCurrentSlide');
			$("[slick-control]").removeClass('active');
			$("[slick-control="+current+"]").addClass('active');
		}

		var sliderHomepage = function() {
			$('#dm-home-top-slider').slick({
				prevArrow: $('.slick-prev'),
				nextArrow: $('.slick-next'),
				arrows: true,
				pauseOnHover: false,
				autoplay: true,
				autoplaySpeed: 3000,
				dots: false,
				slide: '.home-slide',
				speed: 400,
				cssEase: 'easeOutExpo',
			});
		};

		var imageGalerie = function(){

			$('#galerieModal').on('show.bs.modal', function (e) {
				setTimeout(function(){
					$("#galerieSlider").slick("refresh");
				}, 300);
			})

			$('.switch-pictures-galerie').click(function(){
				$('.switch-pictures-galerie').removeClass('selected');
				$(this).addClass('selected');
				$('#galerieSlider').slick('removeSlide', null, null, true);
				var imagesHTML = $('.image-bank[data-category="' + $(this).attr('data-category') + '"]').html();
				$('#galerieSlider').slick("slickAdd",imagesHTML);
				$("#galerieSlider").slick("refresh");
			});

			$('#galerieSlider').slick({
				prevArrow: $('.slick-prev-galerie'),
				nextArrow: $('.slick-next-galerie'),
				arrows: true,
				dots: false,
				slide: '.single-slide',
				fade: true,
				adaptiveHeight: true
				// speed: 500,
				// cssEase: 'easeOutExpo'
			});
		};

		var plans = function(){

			$('.c-single-unit__btn').not('.js-video').click(function(){
				//console.info($(this).attr('data-unit-number'));
				$('#unit-number').val($(this).attr('data-unit-number'));
			});

			$('.js-video').click(function(){
				//console.info($(this).attr('data-unit-number'));
				var youtube_id = $(this).attr('data-video-id');
				if(!player) {
					//console.info('create new player');
					player = new YT.Player('unit-youtube-video', {
						videoId: youtube_id,
						events: {
							'onReady': function(){
								player.playVideo();
							},
						}
					});
				} else {
					//console.info('player exists, load new video');
					player.loadVideoById(youtube_id);
					player.playVideo();
				}
			});

			$('#videoModal').on('hidden.bs.modal', function () {
				player.pauseVideo();
			});

			$('.js-visite-virtuelle').click(function(){
				//console.info($(this).attr('data-unit-number'));
				var visite_id = $(this).attr('data-video-id');
				if (visite_id != "") {
					jQuery('#visiteModal #unit-matterport-video').html('<iframe src="https://my.matterport.com/show/?m='+visite_id+'" width="853" height="480" frameborder="0" allowfullscreen="allowfullscreen"></iframe>');
				}
			});
		}

		var setCurrentUnit = function(){

		}

		var fixedMenu = function() {

			if($('body').attr('data-is-subsite') == 'true'){

				$('.main-nav').hide();
			}

		};

		var smoothScroll = function(section){ // Smoothscroll to a section
			$('html, body').animate({ scrollTop:$('#'+section).offset().top - 75 }, 600);
		};

		var mobileMenu = function(){

			var menuOuvert = false;

			$(window).scroll(function(){

				if($('body').attr('data-is-subsite') == 'true' && $(document).scrollTop() > ($('section#top-microsite').height() + 160 - 75)){
					$('.menuMobile').removeClass('menuMobileOpened');
					$('#nav-icon4').removeClass('open');
					menuOuvert = false;
				}
				else if($('body').attr('data-is-subsite') == 'true' && $(document).scrollTop() < ($('section#top-microsite').height() + 160 - 75) && menuOuvert==true){
					$('.menuMobile').removeClass('menuMobileOpened');
					$('#nav-icon4').removeClass('open');
					menuOuvert = false;
				}
				else if($('body').attr('data-is-subsite') == 'false'){
					$('.menuMobile').removeClass('menuMobileOpened');
					$('#nav-icon4').removeClass('open');
					menuOuvert = false;
				}
			});

			$('#nav-icon4').click(function(event){
				event.stopPropagation();

				if(!menuOuvert){

					if($('body').attr('data-is-subsite') == 'true' && $(document).scrollTop() < ($('section#top-microsite').height() + 160 - 75)){
						$('html, body').animate({ scrollTop: ($('section#top-microsite').height() + 160 - 75) }, 400,function(){
							$('.menuMobile').addClass('menuMobileOpened');
							menuOuvert = true;
							$('#nav-icon4').addClass('open');
						});
					}else{
						$('.menuMobile').addClass('menuMobileOpened');
						menuOuvert = true;
						$('#nav-icon4').addClass('open');
					}

				}
				else{
					$('.menuMobile').removeClass('menuMobileOpened');
					menuOuvert = false;
					$('#nav-icon4').removeClass('open');
				}
				event.stopPropagation();
			});

			$('body').click(function(){
				if(menuOuvert){
					$('.menuMobile').removeClass('menuMobileOpened');
					menuOuvert = false;
					$('#nav-icon4').removeClass('open');
				}
			});

		}

		var init = function() {
			mobileMenu();
			sliderDMProjectHomepage();
			sliderDMHomepage();
			sliderHomepage();
			fixedMenu();
			openModals();
			plans();

			// $(window).resize(function(){
			// 	fixedMenu();
			// });

			if($('#scroll-to-project').length){
				$('#scroll-to-project').click(function(){
					smoothScroll('a-propos');
				});
			}

			if($('.scroll-to-avantages').length){
				$('.scroll-to-avantages').click(function(){
					smoothScroll('avantages');
				});
			}

			if($('.scroll-to-proximite').length){
				$('.scroll-to-proximite').click(function(){
					smoothScroll('proximite');
				});
			}

			if($('.scroll-to-plans').length){
				$('.scroll-to-plans').click(function(){
					smoothScroll('plans');
				});
			}

			if($('#galerieModal').length){
				imageGalerie();
			}

		};

		return {
			init: init
		};
	})();

})(jQuery, window, document);

$(document).ready(APP.util.init);

$(window).load(function(){

	var distanceTop = $('.main-nav').height();

	if($('body').attr('data-is-subsite') == 'true'){

		$('.main-nav').fadeIn(300);

		if ( $(document).scrollTop() >= distanceTop ) {
			$('body').addClass('page-scrolled');
			//$('.main-nav').css('top','');
			//$('.main-nav').css('position','');
			//$('#top-microsite').css('marginBottom','85px');
		}else{
			$('body').removeClass('page-scrolled');
			//$('.main-nav').css('top',distanceTop);
			//$('.main-nav').css('position','absolute');
			//$('#top-microsite').css('marginBottom','');


		}

		$(document).scroll(function() {
			if ( $(document).scrollTop() >= distanceTop ) {
				$('body').addClass('page-scrolled');
				//$('.main-nav').css('top','');
				//$('.main-nav').css('position','');
				//$('#top-microsite').css('marginBottom','85px');

			}else{
				$('body').removeClass('page-scrolled');
				//$('.main-nav').css('top',distanceTop);
				//$('.main-nav').css('position','absolute');
				//$('#top-microsite').css('marginBottom','');

			}
		});

	}else{

		if ( $(document).scrollTop() >= 1 ) {
			$('body').addClass('page-scrolled');
		}else{
			$('body').removeClass('page-scrolled');
		}

		$(document).scroll(function() {
			if ( $(document).scrollTop() >= 1 ) {
				$('body').addClass('page-scrolled');
			}else{
				$('body').removeClass('page-scrolled');
			}
		});

	}

});

$(document).ready(function() {

	if ($('body').attr('data-theme') == 'laprairie') {
		$('.js-change-text').html('La Prairie');
	}

	if ($('body').attr('data-theme') == 'longueuil') {
		$('.js-change-text').html('Longueuil');
	}
});

$(document).ready(function() {
	//Fonction to strigger scroll to different desctions in a page
	$('.scrollTo').click(function(event){
		event.preventDefault();
		smoothScroll($(this).attr('href'));
		return false;
	});

	function smoothScroll(section){ // Smoothscroll to a section
		$('html, body').animate({ scrollTop:$("#"+section).offset().top - 80 }, 800);
	}
})

$(window).scroll(function() {
	var offset = $(window).scrollTop(),
	headerHeight = $('.main-nav').outerHeight(),
	bannerHeight = $('.banner-unites').outerHeight(),
	gap = headerHeight + bannerHeight;

	if (offset >= gap) {
		$('.sticky-units').addClass('is-sticky').css('top', headerHeight + 'px');
		$('.banner-unites').css('margin-bottom', '71px');
	} else {
		$('.sticky-units').removeClass('is-sticky');
		$('.banner-unites').css('margin-bottom', '0');
	}
})


function onYouTubeIframeAPIReady() {
	//console.info('loaded youtube api');
}

function sliderHeight () {

	var hH = $('header').outerHeight();
	var wH = $(window).height();
	var x = wH - hH;

	$('.projects-slider').css('height', x + 'px');
}

function openDropdown(element) {
	element.classList.toggle('open');
	document.getElementsByTagName('body')[0].classList.toggle('open');
};

$(document).ready(function() {
	$('.emploi_detail').click(function() {
		$(this).find('.emploi_content').toggleClass('active');
		$(this).find('.arrow-down-close').toggleClass('open');
	});

	jQuery(document).on('change', '.c-file input', function() {
		var $fileComponent = jQuery(this).closest( ".c-file" );
		jQuery( ".c-file__info" ).remove();

		if (jQuery(this).val().length) {
			var filename = jQuery(this).val().replace(/C:\\fakepath\\/i, '');
			$fileComponent.append(
				'<div class="c-file__info"><span class="c-file__remove"></span><span class="c-file__data"><span class="c-file__name">' + filename + '</span></span></div>'
			);
		}
	});

	jQuery(document).on('click', '.c-file__remove', function(event) {
		event.preventDefault();

		var $fileComponent = jQuery(this).closest( '.c-file' );
		jQuery( '.c-file__info' ).remove();
		$fileComponent.find('input').val('');
	});
});



$(document).ready(function() {
	sliderHeight();
});

$(window).resize(function() {
	sliderHeight();

})